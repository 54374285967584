* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
}

.bg-red { fill: #f87171; }
.bg-blue { fill: #3b82f6; }
.bg-yellow { fill: #ffdf61; }
.bg-darkred{ fill: #991b1b; }

.react-transform-component{
  height: 100vh !important;
  display: flex !important;
  align-content: center;
  width: 100vw !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}